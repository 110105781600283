// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import 'main';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$frontend-prototype-primary: mat.define-palette(mat.$indigo-palette);
$frontend-prototype-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$frontend-prototype-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$frontend-prototype-theme: mat.define-light-theme((
        color: (
                primary: $frontend-prototype-primary,
                accent: $frontend-prototype-accent,
                warn: $frontend-prototype-warn,
        )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($frontend-prototype-theme);

@import "node_modules/bootstrap/scss/bootstrap";
// then import toastr scss
// regular style toast
@import 'node_modules/ngx-toastr/toastr';
@import 'toastr-bs5-alert';

// for select, multiselect
@import "node_modules/@ng-select/ng-select/themes/default.theme.css";
html {
    font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif !important;
    --font-family-sans-serif: "Open Sans";
}

* {
    -webkit-font-smoothing: antialiased;
}

.material-icons {
    position: relative;
    top: -3px;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
}

.notification-container {
    top: 100px;
}

body {
    font-size: 0.875rem;
}

/**
** Generic classes for general usage specifically useful to avoid inline styling for simple stuff like padding / margins, etc.
**/
.p-0 {
    padding: 0;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

///////////////// End of generic classes /////////////////

/**
** Toast related classes (overrides to bootstrap classes)
 */


// Toast
.toast-header {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem 0 0.75rem;
    color: inherit;
    background-color: transparent !important;
    border: 0 !important;
    border-radius: 0 !important;

    .toast-close-button {
        position: relative;
        right: -0.3em;
        top: -0.3em;
        float: right;
        font-size: 13px;
        color: #006f52 !important;
        text-shadow: 0 1px 0 #fff;
        padding: 0;

        :hover,
        :focus {
            color: #000000;
            text-decoration: none;
            cursor: pointer;
            opacity: 0.75;
        }
    }
}

///////////////// End of toast-related classes /////////////////

.msp__campaigns__table {

    .mat-mdc-select-value-text {
        @extend .text--s;
    }

    .color-preview {
        //override size for this table
        width: 16px !important;
        height: 16px !important;
    }

    .draft-status {
        .mat-mdc-select-value-text {
            color: #B4C0E9;
        }
    }

    .planned-status {
        .mat-mdc-select-value-text {
            color: $success;
        }
    }
}

app-table-cell > * {
    padding: 0 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    word-break: keep-all;
}

.feather {
    width: 1.25rem;
    height: 1.25rem;
    vertical-align: text-top;
}

.mat-icon {
    width: 1.25rem;
    height: 1.25rem;
    vertical-align: text-top;
}

.mat-icon.smaller {
    font-size: 20px;
}

.navbar {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .1);
}

.navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    box-sizing: content-box;
}

.card-body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.nav-link .change-value-decorator {
    font-size: 1rem !important;
}

.col-2dot4,
.col-sm-2dot4,
.col-md-2dot4,
.col-lg-2dot4,
.col-xl-2dot4 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}

@media (min-width: 540px) {
    .col-sm-2dot4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media (min-width: 720px) {
    .col-md-2dot4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media (min-width: 960px) {
    .col-lg-2dot4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media (min-width: 1140px) {
    .col-xl-2dot4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

.f-16 {
    font-size: 16px;
}

.handover {
    cursor: pointer !important;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
    pointer-events: none;
}

.scrollX {
    overflow-x: auto;
    overflow-y: hidden;
}

.scrollY {
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollXY {
    overflow: auto;
}

.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.1;
    background-color: gray;
    z-index: $z-index-full-screen-modal-backdrop;

}

.modal-z-index {
    z-index: $z-index-popup;
}

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-checkmark-path {
    stroke: #043d5c !important;
}

.mat-checkbox-background {
    background-color: #f8f9fa !important;
}

.mat-checkbox-inner-container {
    height: 24px;
    width: 24px;
    border: 2px solid #043d5c !important;
}

.mat-mdc-tooltip {
    font-size: 0.8rem;
}

/* mat-form-field size */
.mat-form-field-flex > .mat-form-field-infix {
    padding: 0.7em 0 !important;
}

.mat-form-field-label-wrapper {
    top: -1.3em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.2em) scale(.75);
    width: 133.33333%;
}

/* end: mat-form-field size */

/* mat-calendar */
.mat-calendar-table-header th {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: #5F6273;
    letter-spacing: 0.3px;
    padding: 0 0 5px 0 !important;
}

.mat-calendar-table-header-divider {
    display: none;
}

.mat-calendar-body-label {
    opacity: 0;
}

.mat-calendar-body-label[colspan="7"] {
    display: none;
}

.mat-calendar-content {
    padding: 0 8px 0 8px;
    height: 200px;
}

.special-date div:first-child {
    background-color: #F8F9FC !important;
    border: 0 !important;
    border-radius: 0 !important;
}

.mat-calendar-body-cell-content {
    z-index: $z-index-0 !important;
    border-radius: 5px !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    width: 100% !important;
    height: 100% !important;

    &.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        background-color: #EDF0F9;
        border: 0;
    }
}

/* end: mat-calendar */

/* Custom styling for material dropdowns */

// The items' dropdown is outside the actual ng-select container hence the separation / duplication of styling
.ng-dropdown-panel {
    border: none;
    border-radius: 4px;

    &.auto-grow {
        min-width: inherit !important;
        width: auto !important;
    }

    &.ng-select-bottom {
        margin-top: 0;
    }

    &.ng-select-top {
        margin-bottom: 0;
    }

    &.ng-select-top {
        margin-bottom: 8px;
    }

    &.ng-select-bottom {
        margin-top: 8px;
    }

    .ng-dropdown-header {
        background: $grey-10;
        border-bottom: none;
        padding-top: 15px;

        &:hover {
            background-color: #ebf5ff;
        }
    }

    .ng-dropdown-panel-items {
        text-align: left;

        .ng-option {
            font-weight: $font-medium;
            color: $grey-90;
            letter-spacing: 0.3px;
            background-color: $grey-10;
            padding: 0.5rem 6px;

            &.ng-option-marked {
                color: $grey-90;
            }

            input[type="checkbox"] {
                height: auto;
            }

            input[type="checkbox"] + span {
                top: -1px;
                position: relative;
                padding-left: 5px;
            }

            &:hover, &.ng-option-selected:hover, &.ng-option-selected.ng-option-marked:hover {
                background-color: #ebf5ff;
            }

            &.ng-option-selected, &.ng-option-selected.ng-option-marked {
                background-color: $grey-10;
            }

            &:first-child {
                padding-top: 1rem;
            }

            &:last-child {
                padding-bottom: 1rem;
            }
        }

        .ng-option-label {
            padding-left: 10px;
        }

        .ng-value-label {
            padding: 0 0 0 10px !important;
        }
    }
}

.ng-select.ng-select-disabled>.ng-select-container {
    pointer-events: none;
}

.ng-select {
    max-width: 100%;
    border: none;

    .ng-option {
        font-size: 0.875rem;
        color: $grey-90;
    }

    .ng-option-marked, .ng-has-value {
        .ng-value-label {
            letter-spacing: 0.01em;
            color: #000000 !important;
        }

    }

    &.ng-select-disabled {
        .ng-option-marked, .ng-has-value {
            .ng-value-label {
                color: $grey-60 !important;
            }
        }
    }

    .ng-placeholder {
        color: #A7ABB9 !important;
    }

    .ng-select-container {
        min-height: 40px !important;
        border: none;
        background-color: $grey-10;
        border-radius: 4px;
        font-weight: 500;
        padding: 0 0 0 10px !important;
        font-size: 14px;

        .ng-value-container {
            padding: 0 0 0 6px !important;

            .ng-input {
                top: 0 !important;
            }

            input {
                border: none !important;
                background-color: transparent !important;
            }
        }
    }

    &.ng-select-opened > .ng-select-container {
        background: $grey-10;
    }

    &.ng-select-multiple {
        .ng-dropdown-panel-items .ng-option {
            padding-left: 20px;
        }

        .ng-select-container .ng-value-container {
            padding-top: 0;

            .ng-placeholder {
                position: absolute;
                padding-top: 5px;
                padding-bottom: 0;
            }

            .ng-value {
                margin-bottom: 0;
            }
        }
    }

    &.ng-select-single.auto-grow .ng-select-container {
        min-width: 200px;

        .ng-value-container {
            padding-right: 10px;
        }
    }

    &.extended-list .ng-dropdown-panel-items {
        max-height: 378px; // For extended dropdowns we show a minimum of 10 items (if available) - this rule overrides default max-height of component (240px)
    }

    &.ng-invalid.ng-touched {
        border: 1px solid #ff7a86;
        border-radius: 0.25rem;
    }

}

//Following classes are custom classes for the scenario editing/creation flow
//They are custom classes for datepicker AND dropdown for dates and effects
// TODO: Move this classes to the specific datepicker component
.custom-dp-msp .ng-star-inserted > input {
    border-radius: 4px;
    border-color: transparent !important;
    background: #F8F9FC !important;
    font-weight: 500;
    padding: 0 0 0 24px !important;
}

.custom-dp-msp .ng-star-inserted > input::placeholder {
    color: #A7ABB9 !important;
}

//end custom classes

.ng-value-label {
    font-size: 0.875rem;
    color: $grey-90;
}

///* end dropdowns*/

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.btn--default {
    white-space: nowrap;
}

.modal-body {

    .close-button {
        position: absolute;
        right: 20px;
        top: 15px;
        cursor: pointer;
        color: #adabba;
    }

    hr {
        width: 100%;
        border-color: #EDF0F9;
        margin-top: 0;
        margin-bottom: 0;
    }

    h4 {
        margin-bottom: 0;
    }

    h4, p {
        padding: 11px 24px;
    }

    .modal-buttons {
        padding: 0 20px 20px;
        float: right;
    }

}

.btn {
    height: auto;
    line-height: 1.75rem;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.26px;
}

.btn-primary {
    border-color: #18A0EB;
    background-color: #18A0EB;

    &:hover {
        border-color: #156197;
        background-color: #156197;
    }
}

.btn-primary:disabled {
    border-color: #18A0EB;
    background-color: #18A0EB;
}

.btn-link {
    color: #18A0EB;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.btn-danger {
    background-color: #FFFFFF;
    border: solid 1px #DAD9DE;
    color: #ef5350;

    &:disabled, &.disabled {
        border: solid 1px #DAD9DE;
        background-color: #FFFFFF;
        color: #ef5350;
    }

    &:hover {
        background-color: #FFFFFF;
        color: #b71c1c;
    }
}

.bigger-modal .modal-dialog {
    min-width: 600px;

    table, table tr, table td {
        border: none !important;
    }

    .mat-mdc-tab-body-wrapper {
        min-height: 250px;
    }
}

app-quantity {
    height: 2.5rem;
    display: flex;
    border: 1px solid #F1F0F3;
    border-radius: 0.25rem;

    &:focus-within {
        border: solid 1px #B9E2F9;
    }

    &.ng-touched.ng-invalid {
        border: 1px solid $error;
    }
}

.input-info {
    line-height: 2.5rem;
    white-space: nowrap;
}

.mat-ink-bar {
    background-color: $blue-500 !important;
}

a {
    text-decoration: none;
}

.table > :not(:first-child) {
    border-top: 0;
}

:root {
    @media (prefers-reduced-motion: no-preference) {
        scroll-behavior: auto;
    }
}

.info-container {
    display: flex;
    @include background-opacity($blue-500, 0.1);
    border-radius: 8px;
    color: $blue-900;
    padding: 20px;
}

// Higher specificity styling for tooltips on MSP for conflict resolution - override
.tooltip__conflict {
    .tooltip-arrow::before {
        border-right-color: $blue-900;
    }

    .tooltip-arrow::before {
        border-left-color: $blue-900;
    }

    .tooltip-inner {
        max-width: 304px !important;

        // Container color
        background: $blue-900 !important;
        @extend .dark-shadow;
        border-radius: 8px !important;

        // Container styling
        text-align: center;
        padding: 10px 15px;

        // Text styling
        color: $cyan-blue-light !important;
        font-weight: 500;
        font-size: 14px;

        // Fix arrow is a little higher than main window
        position: relative;
        top: -5px;
    }

}

//Generic Tab navigation styling - avoid usage of ng-deep which is deprecated
.main-tabs {
    .mat-mdc-tab-body-content {
        padding-bottom: 350px;
    }

    & > .mat-mdc-tab-header {
        border-bottom: 1px solid rgba(0, 0, 0, .12);
        margin-bottom: 30px;
        border-bottom: 0;
        max-width: 70%;

        .mat-mdc-tab {
            height: 40px !important;
            opacity: 1 !important;
            min-width: 120px;
            color: $grey-90;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.4px;
            text-transform: capitalize;
            border: 1px solid #DEE1FA;
            background: $white;

            &:not(.last) {
                border-right: none;
            }

            &:first-child {
                border-radius: 6px 0 0 6px;
            }

            &.last {
                border-radius: 0 6px 6px 0;
            }
        }

        .mat-mdc-tab.mdc-tab--active {
            background: rgba(237, 240, 249, 0.47);

            .mdc-tab__text-label {
                color: inherit;
            }
        }

        .mdc-tab-indicator {
            display: none;
        }
    }
}

// Generic styling for vertical tab navigation
.main-tabs-vertical {
    .mdc-tab {
        justify-content: left !important;
    }

    .mat-mdc-tab-labels {
        flex-direction: column;
    }

    &.mat-mdc-tab-group {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .mat-mdc-tab-body-wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .mat-mdc-tab-body {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    & > .mat-mdc-tab-header {
        .mdc-tab {
            padding: 0 16px;
            border-radius: 8px;
        }

        .mdc-tab--active {
            font-weight: 700;
            background-color: $cyan-blue-light !important;
        }

        .mdc-tab-indicator {
            display: none;
        }

        .mdc-tab__text-label {
            font-size: 16px;
            color: $blue-900 !important;
        }
    }
}

// Generic styling for horizontal tab navigation
.main-tabs-horizontal {
    .mdc-tab {
        justify-content: center !important;
    }

    .mat-mdc-tab-labels {
        flex-direction: row; // the tab direction
    }

    &.mat-mdc-tab-group {
        flex-grow: 1;
        display: flex;
        flex-direction: column; // no
    }

    .mat-mdc-tab-body-wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .mat-mdc-tab-body {
        flex-grow: 1;
        display: block;
        flex-direction: column;
    }

    & > .mat-mdc-tab-header {
        .mdc-tab {
            padding: 0 16px;
            border-radius: 8px;
        }

        .mdc-tab--active {
            font-weight: 700;
            background-color: $cyan-blue-light !important;
        }

        .mdc-tab-indicator {
            display: none;
        }

        .mdc-tab__text-label {
            font-size: 16px;
            color: $blue-900 !important;
        }
    }
}

.secondary-tabs {

    .mat-mdc-tab-labels {
        flex-direction: row;
    }

    .no-padding-tab {
        .mat-mdc-tab-body-content {
            padding: 0;
        }
    }

    .mat-mdc-tab-body-content {
        padding: 20px;

        h3 {
            margin-bottom: 0;
        }
    }

    & > .mat-mdc-tab-header {
        border-bottom: 1px solid rgba(0, 0, 0, .12);
        border-bottom-color: #F1F0F3;

        .mat-mdc-tab {
            opacity: 1;
            min-width: 160px;
            letter-spacing: inherit;
            flex-grow: 0 !important;
            flex: none !important;

            .mdc-tab__text-label {
                &.big-text {
                    font-size: 16px;
                }

                font-weight: 700;
                font-size: 14px;
                line-height: 19px;
                color: $grey-60;
            }
        }

        .mat-mdc-tab.mdc-tab--active {
            color: $blue-500;
            border-bottom: 3px solid;

            .mdc-tab__text-label {
                color: inherit;
            }
        }

        .mdc-tab-indicator {
            display: none;
        }
    }
}

.datepicker-dashboard-tabs {
    .mat-mdc-tab-body {
        min-height: 800px !important;
    }
}

.sortable-list-item:hover {
    color: black;
}

.list-group-item:hover {
    background-color: aliceblue;
    color: black;

    .badge {
        color: black;
    }
}

.list-group-item {
    cursor: pointer;
}

.show-more {
    background-color: $blue-500;
}

.show-more:hover {
    color: black;
}

.addForecastForm.mat-mdc-menu-panel, .addForecastForm .mat-mdc-menu-content {
    max-height: none !important;
    max-width: none !important;
    height: 100%;
    width: 100%;
    padding: 0 !important;
    overflow: visible !important;

    .card {
        margin-bottom: 0;

        input, .ng-select {
            width: 16rem;
            margin-bottom: 1.25rem;
        }
    }

    h3 {
        color: $grey-90;
    }
}

// Custom checkbox and radio-button styling in msp forecasts
.chart-option-collapsible, .chart-options {

    margin-top: 0.5rem;

    .datepicker {
        width: 75%;
    }

    .mdc-radio__background::before {
        background: none !important;
    }

    .mat-mdc-radio-button {
        display: inline-block;

        .mdc-radio {
            padding: 0 !important;
        }
    }

    .mdc-radio__outer-circle {
        border: 1px solid #C5C5CF !important;
    }

    .mdc-radio,
    .mdc-radio__background,
    .mat-radio-label,
    .mat-form-field,
    .mdc-radio__outer-circle {
        height: 16px;
        width: 16px;
    }

    .mdc-radio__inner-circle {
        border-width: 8px;
        height: 16px;
        width: 16px;
    }

    .mat-mdc-radio-button .mat-radio-ripple {
        height: 32px;
        width: 32px;
        left: calc(50% - 16px);
        top: calc(50% - 16px);
    }

    .mat-radio-container,
    .mat-checkbox-inner-container {
        top: -1.5px
    }

    .mat-checkbox-inner-container {
        background-color: white;
        border-radius: 4px;
    }

    .mdc-radio__native-control,
    .mat-mdc-radio-touch-target {
        height: 16px !important;
        width: 16px !important;
    }
}

.chart-options {
    margin-top: 0.5rem;

    input[type="checkbox"] {
        min-width: 1rem;
        min-height: 1rem;
        margin-right: 5px;
    }

    label {
        color: $grey-80;
    }
}

.chart-option-collapsible {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    width: 100%;

    span {
        font-size: 14px;
        font-weight: 500;
        color: $grey-80;
    }

    mat-icon {
        top: 0;
        right: -8px;
        color: $grey-60;
    }

    &.inner {
        display: block;

        mat-icon {
            top: -3px;
        }
    }
}

.hide-chart-options {
    display: none;
}

.page-title {
    padding-bottom: 10px;
}

.container-fluid {
    padding-top: 15px;
    padding-bottom: 15px;
}


/* Overwrite the default to keep the scrollbar always visible */
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

// Styling material stepper wizard
.mat-step-icon {
    background-color: $blue-200 !important;
    color: $black !important;
    font-weight: 700;

    .mat-icon {
        top: 0 !important;
    }

    &.mat-step-icon-selected {
        color: $white !important;
        background-color: $black !important;
    }
}

.mat-step-label {
    color: $grey-40;
}

.mat-step-label.mat-step-label-selected {
    color: $black;
}

.highcharts-container {
    width: 100% !important;
}

.highcharts-root {
    width: 100% !important;
}

.accordion-button:not(.collapsed) {
    color: black !important;
    background-color: white !important;
    box-shadow: none !important;
}

.accordion {
    --bs-accordion-btn-focus-border-color: none !important;
    --bs-accordion-btn-focus-box-shadow: none !important;
}

.accordion-button {
    cursor: pointer;
}

.accordion-item {
    box-shadow: 0 1px 4px $grey-shadow-10;
    border-radius: 12px !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-radius: 12px !important;
}

.accordion-item:first-of-type .accordion-button {
    border-radius: 12px !important;
}

.cursor-pointer {
    cursor: pointer;
}

// pagination style for app-table
.page-link.active, .active > .page-link {
    color: #7C7789 !important;
    background-color: $grey-10 !important;
    border-color: var(--bs-pagination-border-color);
}

.page-link {
    color: #7C7789 !important;
}

.page-link:focus {
    box-shadow: var(--bs-pagination-border-color);
}

.table__text {
    font-style: normal;
    font-weight: 700;
    font-size: 14px !important;
    line-height: 16px;
    color: $grey-80 !important;
}

.table__text__light {
    @extend .table__text;
    font-weight: 500 !important;
}

.table__text__disabled {
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 16px;
    color: $grey-90 !important;
    opacity: 0.6;
}

.item-badge {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.25px;
    text-align: left;
    border-radius: 12px;
    padding: 4px 13px 4px 13px;
}

.item-badge-red {
    background-color: $error;
    color: #000000;
}

.item-badge-warning {
    background-color: $warning;
    color: #000000;
}

.item-badge-green {
    background-color: $success;
    color: #000000;
}

.item-badge-grey {
    background-color: $cyan-blue-light;
    color: #000000;
}

.item-badge-dark-grey {
    background-color: #6D7DB966;
    color: #000000;
}

.item-badge-live {
    background-color: $skyblue-20;
    color: $skyblue-50;
    text-transform: uppercase;
}

.item-badge-blue {
    background-color: #0f63d1;
    color: white;
}

.item-badge-purple {
    background-color: mediumpurple;
    color: white;
}

.item-badge-orange {
    background-color: $orange-600;
    color: #121212;
}

.item-badge-grey-750 {
    background-color: #4b4848;
    color: $white;
}

.item-badge-black {
    background-color: $black;
    color: $white;
}

.item-badge-running {
    background-color: #6D7DB966;
    color: $blue-900;
}

.item-badge-completed {
    background-color: #83E873;
    color: #2B725F;
}

.item-badge-failed {
    background-color: #ff9a7acc;
    color: #99010F;
}

.item-badge-rejected {
    background-color: #ff7a86cc;
    color: #99010f;
}

.item-badge-approved {
    background-color: #00d69e66;
    color: #195344;
}

.item-badge-draft {
    background-color: #f5f5f5;
    color: #132e53;
}

.item-badge-reviewing {
    background-color: #969595;
    color: #132e53;
}

.item-badge-archived {
    background-color: #f1f0f3;
    color: #5f6273;
}

.skeleton-loader {
    margin-bottom: 20px !important;
}

.mat-mdc-tab-header-pagination {
    z-index: 1 !important;
}

.mat-slide-toggle-content {
    margin-right: 10px;
    color: $blue-800;
}

.mat-mdc-slide-toggle {
    label {
        margin-right: 10px !important;
        color: $blue-800 !important;
        cursor: pointer;
    }
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
    background: $blue-500 !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before {
    background: #00000061 !important;
}

.mdc-switch__ripple {
    display: none !important;
}

.mat-mdc-slide-toggle .mdc-switch__handle {
    &:after, &:before {
        background: $white !important;
    }
}

.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: $blue-500;
}


.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: white;
}

/* Expandable menu customization is MSP */
.collapsible__options-menu {

    input {
        max-width: 160px;
    }

    .mat-expanded:last-of-type {
        padding-bottom: 1rem !important;
    }

    .mat-expansion-panel-content {
        padding: 0 24px !important;
    }

    .mat-expansion-panel-body {
        padding: 0 !important;
    }
}

.hidden-element {
    display: none !important;
}

.vertical-input-container {
    display: inline-block;

    * {
        display: block;
    }

    > :nth-child(2) {
        margin-top: 0.3rem;
    }
}

.mat-mdc-card-outlined {
    border-width: 0 !important;
}

.mat-mdc-card {
    padding: 16px;
}

.mdc-menu-surface.mat-mdc-select-panel {
    width: unset !important;
    position: absolute !important;
}

.mat-pseudo-checkbox {
    display: none !important;
}

.mat-icon {
    overflow: visible !important;
}

.mdc-list-item__primary-text {
    font-size: 14px !important;
    font-weight: 400 !important;
}

.ng-dropdown-panel {
    z-index: 10 !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border: none !important;
    box-shadow: none !important;
}

.mat-mdc-form-field {
    display: inherit !important;
}

.mdc-text-field--filled, .mdc-text-field--focused {
    background-color: inherit !important;
}

.mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
}

.mat-mdc-button {
    font-family: Roboto, Helvetica Neue, sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: normal !important;
}

.mat-step-label-selected {
    font-size: 14px !important;
    font-weight: 500 !important;
}

.mdc-button {
    padding: inherit !important;
}

.mat-mdc-row, .mat-mdc-header-row, .mat-mdc-footer-row {
    min-height: 56px;
}

.mat-mdc-header-cell {
    color: #0000008a !important;
    font-size: 12px !important;
    font-weight: 500 !important;
}

ng-select.required.ng-invalid.ng-touched {
    .ng-select-container {
        border-color: #ff7a86 !important;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 1px #ff7a86 !important;
    }
}

.datepicker-mandatory-error {
    .datepicker {
        border-color: #ff7a86 !important;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 1px #ff7a86 !important;
    }
}

.streamlit-filters { //** very ugly solution for streamlit filters, can't think of better one yet **/
    .datepicker__dialog.range.shorter {
        position: absolute;
    }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    top: 0 !important;
    bottom: 0 !important;
    position: sticky !important;
    background: #FFFFFF;
}

.ml-5 {
    margin-left: 5px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

revogr-scroll-virtual {
    height: 7px;
}

revo-grid [hidden] {
    display: initial !important;
}

revogr-header .rgHeaderCell .header-content {
    text-align: center;
}

revo-grid[theme=material] revogr-header .rgHeaderCell {
    border-right: 1px solid #f1f1f1;
    padding: 0px;
}

revo-grid[theme=material] revogr-data .rgCell.disabled {
    opacity: 0.8;
    cursor: cell !important;
    pointer-events: all;
}

revo-grid[theme=material] revogr-data .rgCell.disabled.handover {
    opacity: 0.8;
    cursor: pointer !important;
}

.count-chip {
    background-color: $blue-500;
    color: white;
    border-radius: 50%;
    width: 19px;
    height: 19px;
    margin-top: 4px;
}

.filter-button {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-left: 5px;
    padding-right: 5px;
}

.counter {
    position: relative;
    display: block;
    font-size: 10px;
    bottom: 4px;
}

.dashboard-fullscreen-graph {
    height: 100%;
    z-index: 2147483647;
    width: 100%;
    background: white;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.hide-dashboard-graph {
    left: -100000px;
    opacity: 0;
}

.hide-dashboard-tabs {
    z-index: 10 !important;
}

.loadingInline {
    color: $grey-80;
}

.mat-mdc-tab-group.mat-mdc-tab-group-dynamic-height .mat-mdc-tab-body.mat-mdc-tab-body-active,
.mat-mdc-tab-group-dynamic-height .mat-mdc-tab-body-content,
.mat-mdc-tab-body-content,
.mat-mdc-tab-body-wrapper {
    overflow-y: visible !important;
    overflow-x: clip !important;
}

.mat-mdc-menu-panel {
    border-radius: 10px !important;
    max-width: fit-content !important;

    .mat-mdc-menu-item {
        min-width: 160px;
        min-height: 43px;
        padding-right: 25px;

        .mat-mdc-menu-item-text {
            font-weight: 700 !important;
            color: #5F6273;
        }

        &.warning {
            .mat-mdc-menu-item-text {
                color: #FF7A86;
            }
        }
    }

}

.smaller-icon {
    transform: scale(0.7);
    color: $grey-90;
}

editor {
    &.ng-touched.ng-invalid {
        .tox.tox-tinymce {
            border: 1px solid $error;
        }
    }
}

.mandatory-hint {
    color: red;
}

.button-icon {
    transform: scale(0.8);
    top: -5px;
    margin-right: 8px;
}

.rotated-icon {
    transform: rotate(180deg);
}

.horizontal-radio-group {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping to the next line */
    gap: 10px; /* Add some space between radio buttons */
    justify-content: flex-start;
}


.info-badge {
    display: inline-block;
    padding-right: 11px;
    padding-left: 11px;
    background-color: #0f63d1; /* Blue background */
    color: white; /* White text */
    border-radius: 8px; /* Makes the shape fully rounded */
    text-align: center; /* Center text */
}

.feature-text {
    font-weight: 400;
    color: #0f63d1;
}

.dashboard-result-table {
    .cell-text {
        font-size: 12px;
    }
}

.cell-text {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    font-weight: 400;
    font-size: 14px !important;
    letter-spacing: 0.3px !important;
    color: $black;
    margin: 0;
    padding: 5px !important;

    &.heavy {
        font-weight: 500;
    }
}

.custom-slide-toggle.mat-mdc-slide-toggle {
    &.smaller {
        transform: scale(0.70);
    }

    .mat-slide-toggle-content {
        margin-right: 10px;
        color: $blue-800;
    }

    .mdc-switch:enabled .mdc-switch__track::after {
        background: $green-70 !important;
    }

    .mdc-switch:enabled .mdc-switch__track::before {
        background: #00000061 !important;
    }

    .mdc-switch__ripple {
        display: none !important;
    }

    .mdc-switch__handle {
        &:after, &:before {
            background: $white !important;
        }
    }

    .mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
        background-color: $green-70;
    }

    .mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
        background-color: white;
    }
}

.custom-tooltip-styling {
    .mdc-tooltip__surface {
        max-width: 330px;
        padding: 7px 15px;
        text-align: center;

        font-size: 13px !important;
    }
}